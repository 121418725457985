<template>
    <div class="h-screen w-full flex justify-center items-center">
        <p class="text-9xl font-medium text-red-500">404 error</p>
    </div>
</template>

<script>
    export default {
        name: "404"
    }
</script>

<style lang="scss" scoped>

</style>